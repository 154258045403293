

.App {
  text-align: center;
  background-color: black;
}


.customgreen{
  color: #37ca37;
}
.customwhite{
  color: #ffffff;
}

.cutom-underline{
  text-decoration-line: underline;
}

.custom-bold-700{
  font-weight: 700;
}

.poppins-regular-400 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.custom-green-hover:hover{
  background-color:#37ca37;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.montserrat-400 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.montserrat-700 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.montserrat-200 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.montserrat-300 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: etna;
  src: url(../src/font/etna-free-font.otf) format();
}

.custom-font{
  font-family: 'etna', sans-serif;
}

@keyframes scale-up {
  0% {
      transform: scale(0);
  }
  100% {
      transform: scale(1);
  }
}
